import React from 'react';
import SeunghwanImage from '../../assets/imgs/img_seunghwan_people.png';
import KyoungMuImage from '../../assets/imgs/img_kyoungmu_people.png';
import YoungjoonImage from '../../assets/imgs/img_mark_people.png';
import PyunghwanImage from '../../assets/imgs/img_pyunghwan_people.png';
import EmilyImage from '../../assets/imgs/img_emily_people.png';
import GwangmoImage from '../../assets/imgs/img_sihaeng_people.png';
import TaehoonImage from '../../assets/imgs/img_taehoon_people.png';
import BohyungImage from '../../assets/imgs/img_bohyung_people.png';
import LarryImage from '../../assets/imgs/img_alessandra_people.png';

type PeopleProps = {
  name: string;
}

type PeopleData = {
  type: 'Organizing' | 'Technical';
  isChair: boolean,
  name: string;
  family: string;
  company: string;
  email: string;
  img: any;
}

const data: PeopleData[] = [
  {
    type: 'Organizing',
    isChair: true,
    name: 'SeungHwan',
    family: 'Kim',
    company: 'LG AI Research',
    email: 'sh.kim@lgresearch.ai',
    img: SeunghwanImage,
  },
  {
    type: 'Organizing',
    isChair: true,
    name: 'KyoungMu',
    family: 'Lee',
    company: 'Seoul National University',
    email: 'kyoungmu@snu.ac.kr',
    img: KyoungMuImage
  },
  {
    type: 'Organizing',
    isChair: false,
    name: 'BoHyung',
    family: 'Han',
    company: 'Seoul National University',
    email: 'bhhan@snu.ac.kr',
    img: BohyungImage
  },
  {
    type: 'Organizing',
    isChair: false,
    name: 'Larry',
    family: 'Davis',
    company: 'Amazon',
    email: 'lrrydav@amazon.com',
    img: LarryImage
  },
  {
    type: 'Technical',
    isChair: false,
    name: 'TaeHoon',
    family: 'Kim',
    company: 'LG AI Research',
    email: 'taehoon.kim@lgresearch.ai',
    img: TaehoonImage
  },
  {
    type: 'Technical',
    isChair: false,
    name: 'PyungHwan',
    family: 'Ahn',
    company: 'LG AI Research',
    email: 'p.ahn@lgresearch.ai',
    img: PyunghwanImage
  },
  {
    type: 'Technical',
    isChair: false,
    name: 'Gwangmo',
    family: 'Song',
    company: 'LG AI Research',
    email: 'gwangmo.song@lgresearch.ai',
    img: GwangmoImage
  },
  {
    type: 'Technical',
    isChair: false,
    name: 'Emily',
    family: 'Webber',
    company: 'Amazon Web Services',
    email: 'egwebber@amazon.com',
    img: EmilyImage
  },
  {
    type: 'Technical',
    isChair: false,
    name: 'Youngjoon',
    family: 'Choi',
    company: 'Amazon Web Services',
    email: 'choijoon@amazon.com',
    img: YoungjoonImage
  },
]

export default function PeopleCard({ name }: PeopleProps) {
  return (
    <div className='flex flex-col'>
      <div 
        className={`${data.find(item => item.name === name)?.type === 'Organizing'? 
                    'w-[240px] h-[324px]' : 'w-[200px] h-[276px]'} bg-white rounded-lg mb-5 flex justify-end items-end`}>
        <img src={data.find(item => item.name === name)?.img} alt="people" />
      </div>
      <h2 className='font-bold text-[20px] mb-2 h-[20px] leading-[20px] text-white'>
        {`${data.find(item =>  item.name === name)?.name} 
          ${data.find(item => item.name === name)?.family}`}
      </h2>
      {data.find(item => item.name === name)?.isChair &&
        <span className='text-[20px] text-white mb-2'>Co-chair</span>
      }
      <span className='text-white text-sm h'>{data.find(item => item.name === name)?.company}</span>
      <span className='text-white text-sm'>{data.find(item => item.name === name)?.email}</span>
    </div>
  )
}
