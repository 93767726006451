import React from 'react'
import companyLogo from '../../assets/imgs/logo@2x.png';
import snuLogo from '../../assets/imgs/logo_snu@2x.png';
import awsLogo from '../../assets/imgs/logo_aws@2x.png';
import taehoon from '../../assets/imgs/image@3x.png';
import pyunghwan from '../../assets/imgs/image@2x.png';

export default function Introduction() {
  return (
    <section className='w-full h-screen bg-black flex flex-col items-center justify-center px-[340px] sm:h-fit sm:px-0'>
      <div className='flex items-center justify-center w-full sm:flex-col'>
        <span className='text-[110px] font-semibold text-[#c90bf9] lg:absolute xl:absolute left-10 sm:text-[40px]'>01</span>
        <h1 className='text-[84px] sm:text-[26px] font-bold bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text'>Introduction</h1>
      </div>
      <h3 className='text-[26px] text-white mb-12 sm:text-[18px] sm:text-center'>
        New frontiers for zero-shot Image Captioning Evaluation (NICE)
      </h3>
      <div className='w-[1240px] flex justify-between sm:w-full sm:flex sm:flex-col sm:items-center sm:gap-7'>
        <div className='flex flex-col sm:items-center'>
          <span className='w-[28px] sm:w-[20px] h-[28px] sm:h-[20px] bg-[#c90bf9] rounded-full font-semibold text-[18px] sm:text-[12px] text-white text-center leading-7 sm:leading-5'>1</span>
          <p className='w-[400px] text-white text-[18px] font-light text-justify leading-[32px] mt-[6px] sm:text-[15px] sm:leading-6 sm:w-[334px]'>
            The project will cover various topics related to image captioning, which include but are not limited to: hallucination of image captioning models, societal bias of vision-language datasets, and controllable image captioning models. It aims to challenge the models to generate reliable captions that explain the image with the expected level of details, while not presenting any wrong information. To accomplish this, the models need to understand the image accurately and thoroughly, and also explain it with the words that accurately correspond to each part of the image.​
          </p>
        </div>
        <div className='flex flex-col sm:items-center'>
          <span className='w-[28px] sm:w-[20px] h-[28px] sm:h-[20px] bg-[#c90bf9] rounded-full font-semibold text-[18px] sm:text-[12px] text-white text-center leading-7 sm:leading-5'>2</span>
          <p className='w-[400px] text-white text-[18px] font-light text-justify leading-[32px] mt-[6px] sm:text-[15px] sm:leading-6 sm:w-[334px]'>
            As a program of the CVPR 2024 workshop, we are organizing challenges on two topics: one on zero-shot image captioning and the other one on caption re-ranking, both of which will focus on estimating resemblance to the human annotations. Various approaches are expected to be presented during the challenge, and through the workshop, we will share novel and reliable methods of zero-shot image captioning and caption re-ranking.
          </p>
        </div>
        <div className='flex flex-col sm:items-center'>
          <span className='w-[28px] sm:w-[20px] h-[28px] sm:h-[20px] bg-[#c90bf9] rounded-full font-semibold text-[18px] sm:text-[12px] text-white text-center leading-7 sm:leading-5'>3</span>
          <p className='w-[400px] text-white text-[18px] font-light text-justify leading-[32px] mt-[6px] sm:text-[15px] sm:leading-6 sm:w-[334px]'>
            At the CVPR 2024 workshop, we will cover a broad range of topics on image understanding and text generation, with deeper consideration on common problems of vision-language models, such as caption hallucination and societal biases. Therefore, we plan to invite researchers to provide talks on various topics under the range of combination of language and vision. The list of related topics includes: zero-shot image captioning, caption hallucination, caption evaluation, multimodal learning, and fairness.
          </p>
        </div>
      </div>
      <div className='w-[1240px] h-[144px] sm:w-[358px] sm:h-fit bg-[#191919] rounded-lg mt-[80px] py-[30px] sm:py-4 px-[40px] sm:px-6 flex sm:flex-col sm:mt-4'>
        <div className='flex flex-col w-[50%] sm:w-full sm:mb-4 sm:border-b sm:border-[#484848] sm:pb-4'>
          <h3 className='font-bold sm:font-normal text-white text-md sm:text-sm mb-[26px] sm:mb-[18px]'>Sponsors</h3>
          <div className='flex items-center sm:flex-col sm:items-start gap-7 sm:gap-4'>
            <img src={companyLogo} alt="company" width={154}/>
            <div className='w-[1px] h-[26px] bg-[#454545] sm:hidden'/>
            <img src={snuLogo} alt="snu" width={224}/>
            <div className='w-[1px] h-[26px] bg-[#454545] sm:hidden'/>
            <img src={awsLogo} alt="aws" width={48}/>
          </div>
        </div>
        <div className='flex flex-col w-[50%] sm:w-full'>
          <h3 className='font-bold sm:font-normal text-white text-md sm:text-sm mb-[16px] sm:mb-[10px]'>Contact</h3>
          <div className='flex gap-6 sm:flex-col'>
            <div className='flex items-start gap-4'>
              <img src={taehoon} alt="contact" width={48}/>
              <div className='grow'>
                <h3 className='text-[13px] leading-[18px] text-white'>Taehoon kim</h3>
                <p className='text-[12px] leading-4 tracking-tight text-[#9f9f9f]'>taehoon.kim@lgresearch.ai<br/>
                  Research Scientist, LG AI Research</p>
              </div>
            </div>
            <div className='flex items-start gap-4'>
              <img src={pyunghwan} alt="contact" width={48}/>
              <div>
                <h3 className='text-[13px] leading-[18px] text-white'>Pyunghwan Ahn</h3>
                <p className='text-[12px] leading-4 tracking-tight text-[#9f9f9f]'>p.ahn@lgresearch.ai</p>
                <p className='text-[12px] leading-4 tracking-tight text-[#9f9f9f]'>Research Scientist, LG AI Research</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
