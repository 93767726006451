import React from 'react'

export default function Workshop() {
  return (
    <section
      className='w-full bg-black lg:h-[2524px] xl:h-[2524px] lg:bg-workshop xl:bg-workshop bg-no-repeat bg-center flex flex-col justify-start items-center relative sm:w-full sm:h-fit sm:bg-black sm:pb-11 sm:px-7'>
      <span
        className='absolute text-[110px] font-semibold text-[#c90bf9] h-[130px] leading-[130px] left-10 top-14 sm:relative sm:text-[40px] sm:h-10 sm:leading-10 sm:left-0 sm:top-0'>04</span>
      <h1
        className='text-[60px] xl:mt-[130px] lg:mt-[130px] font-bold bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text sm:text-[26px] sm:mb-5'>Workshop</h1>
      <h2 className='sm:font-normal sm:text-white sm:text-[15px] sm:text-center sm:mb-16 lg:hidden xl: hidden'>
        NICE: New frontiers for zero-shot <br/> Image Captioning Evaluation, June 18th, Summit 323
      </h2>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className='sm:flex sm:flex-col sm:w-full sm:mb-[44px] lg:hidden xl:hidden'>
        <span className="sm:text-[#c90bf9] sm:text-lg sm:font-medium sm:mb-3">Session 1</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-6">Introduction & Organizer Talk 1</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">09:00 - 09:05</span>
        <span className="sm:text-[#d3d3db] sm:text-lg sm:mb-3">Opening Remarks</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Kyoung Mu Lee (SNU)</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">09:05 - 09:20</span>
        <span className="sm:text-[#d3d3db] sm:text-lg sm:mb-3">NICE Dataset & Challenge Review</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Seung Hwan Kim (LGAI)</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">09:20 - 09:40</span>
        <span className="sm:text-[#d3d3db] sm:text-lg sm:mb-3">Challenge Winner Talk</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">NICE Challenge Topic 2 Winner</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-6">Organizer Talk 2</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">09:40 - 10:00</span>
        <span className="sm:text-[#797b83] sm:text-[15px]">Larry Davis (Amazon) & Emily Webber (AWS)</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className='sm:flex sm:flex-col sm:w-full sm:mb-5 lg:hidden xl:hidden'>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Coffee Break</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px]">10:00 - 10:30</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className='sm:flex sm:flex-col sm:w-full sm:mb-[44px] lg:hidden xl:hidden'>
        <span className="sm:text-[#c90bf9] sm:text-lg sm:font-medium sm:mb-[15px]">Session 2</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Invited Talk 1</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">10:30 - 11:00</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Cordelia Schmid</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Invited Talk 2</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">11:00 - 11:30</span>
        <span className="sm:text-[#797b83] sm:text-[15px]">Lambert Mathias</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className="sm:flex sm:flex-col sm:w-full sm:mb-5 lg:hidden xl:hidden">
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Lunch Break</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px]">11:30 - 13:00</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className='sm:flex sm:flex-col sm:w-full sm:mb-[44px] lg:hidden xl:hidden'>
        <span className="sm:text-[#c90bf9] sm:text-lg sm:font-medium sm:mb-3">Session 3</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Keynote Speech</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">13:00 - 13:30</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Honglak Lee</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Invited Talk 3</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">13:30 - 14:00</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Noa Garcia</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Invited Talk 4</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">14:00 - 14:30</span>
        <span className="sm:text-[#797b83] sm:text-[15px]">Elisa Kreiss</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className="sm:flex sm:flex-col sm:w-full sm:mb-5 lg:hidden xl:hidden">
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Coffee Break</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px]">14:30 - 15:00</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className='sm:flex sm:flex-col sm:w-full sm:mb-[44px] lg:hidden xl:hidden'>
        <span className="sm:text-[#c90bf9] sm:text-lg sm:font-medium sm:mb-3">Session 4</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">CVPR Paper Talk 1</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">15:00 - 15:30</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Yair Kittenplon</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">CVPR Paper Talk 2</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">15:30 - 16:00</span>
        <span className="sm:text-[#797b83] sm:text-[15px] sm:mb-6">Xiang Yue</span>
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">CVPR Paper Talk 3</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px] sm:mb-3">16:00 - 16:30</span>
        <span className="sm:text-[#797b83] sm:text-[15px]">Jiasen Lu</span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px] sm:mb-4"/>
      <div className="sm:flex sm:flex-col sm:w-full sm:mb-5 lg:hidden xl:hidden">
        <span className="sm:text-[#fff] sm:text-xl sm:font-medium sm:mb-3">Closing</span>
        <span className="sm:text-[#c90bf9] sm:text-[15px]">16:30 - </span>
      </div>
      <div className="sm:bg-[#353535] sm:w-full sm:h-[1px]"/>
      <div className="sm:flex sm:flex-col sm:w-full sm:mt-8 lg:hidden xl:hidden">
        <h2 className="sm:text-xl sm:font-medium sm:text-[#dee0e2] sm:mb-6">CVPR Paper Talks</h2>
        <ul className="sm:text-[#c2c4c8] sm:flex sm:flex-col sm:gap-3.5 sm:tracking-[0.16px]">
          <li>
            [1] Question Aware Vision Transformer for <br/> Multimodal Reasoning&nbsp;&nbsp;|
            <span className="text-[#797b83]">&nbsp;&nbsp;Yair Kittenplon</span>
          </li>
          <li>
            [2] MMMU : A Massive Multi-discipline Multimodal Understanding and Reasoning Benchmark for Expert AGI&nbsp;&nbsp;|
            <span className="text-[#797b83]">&nbsp;&nbsp;Xiang Yue</span>
          </li>
          <li>
            [3] Unified-IO 2 : Scaling Autoregressive Multimodal Models with Vision, Language, Audio, and Action&nbsp;&nbsp;|&nbsp;&nbsp;
            <br /> <span className="text-[#797b83]">Jiasen Lu</span>
          </li>
        </ul>
      </div>
    </section>
  )
}
