import React, { useRef } from 'react';
import { ReactComponent as WhiteLogo } from '../assets/imgs/Logo_white.svg';

export default function Footer() {
  return (
    <div className='w-full h-[68px] bg-white flex items-center justify-between px-10 sm:h-[238px] sm:flex-col sm:px-5 sm:pt-7 sm:pb-5 sm:justify-between sm:items-center'>
      <div className='flex gap-10 sm:gap-0 sm:w-full sm:justify-between'>
        <WhiteLogo />
        <div className='flex gap-9 sm:flex-col'>
          <span 
            className='text-[#b6b6b6] font-semibold cursor-pointer' 
            onClick={() => {
              window.open('https://www.lgresearch.ai/careers');
            }}
          >
            CAREERS
          </span>
          <span 
            className='text-[#b6b6b6] font-semibold cursor-pointer' 
            onClick={() => {
              window.open('https://www.lgresearch.ai/policies');
            }}
          >
            POLICIES
          </span>
          <span
            className='text-[#b6b6b6] font-semibold cursor-pointer'
            onClick={() => {
              window.open('https://www.lgresearch.ai/policies');
            }}
          >
            Ethics Hotline</span>
        </div>
      </div>
      <div>
        <span className='text-[#bdbdbd] text-sm sm:text-center sm:w-full'>
          ⓒ 2022. LG AI Research. All Rights Reserved.
        </span>
      </div>
    </div>
  )
}
