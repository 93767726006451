import React, { useState, useRef } from 'react';
import arrowDown from '../assets/imgs/btn_select_year_n@2x.png';

const Menus: string[] = ['Home', 'Introduction', 'Challenge', 'Dataset', 'Workshop', 'People'];

interface HeaderProps {
  year: string;
  onYearSelect: (year: number) => void;
  onMenuClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function Header({ year, onYearSelect, onMenuClick } : HeaderProps) {
  const [isYearSelect, setIsYearSelect] = useState<boolean>(false);
  return (
    <header className='w-full h-[68px] bg-[#121212] font-medium text-white flex items-center justify-center sm:hidden'>
      <div className='absolute flex items-center gap-4 left-10'>
        <h1 className='font-bold text-[22px]'>
          CVPR {year} NICE Workshop
        </h1>
        <img
          className='cursor-pointer'
          src={arrowDown} 
          alt="arrow-down" 
          width={32}
          onClick={() => {
            setIsYearSelect((prev) =>(!prev));
          }}
        />
      </div>
      <nav className='flex gap-12 list-none cursor-pointer'>
        {Menus.map((item, index) =>
          <li key={index} onClick={onMenuClick}>{item}</li>
        )
        }
      </nav>
    </header>
  )
}
