import React from 'react';
import logo2024 from '../../assets/imgs/img_logo_cvpr2024@2x.png';

export default function Home() {
  return (
    <section className='w-full h-screen bg-center bg-no-repeat bg-cover bg-mainVideo sm:w-full sm:h-[612px] sm:bg-mobileVideo'>
      <div className='w-full h-full flex flex-col justify-center xl:pl-[240px] lg:pl-[240px] sm:pl-[28px]'>
        <img className='xl:mb-[68px] lg:mb-[68px] sm:hidden' src={logo2024} alt="logo" width={181}/>
        <img className='xl:hidden lg:hidden sm:mb-11' src={logo2024} alt="logo" width={88}/>
        <p className='lg:text-[104px] xl:text-[104px] lg:leading-[120px] xl:leading-[120px] font-semibold text-white sm:text-[26px] sm:leading-10'>New Frontiers for Zero-shot</p>
        <p className='sm:text-[32px] lg:text-[104px] xl:text-[104px] sm:font-bold font-semibold 
                      bg-gradient-to-l from-[#b15bff] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text 
                      sm:leading-[38px] leading-[120px] mb-[80px] sm:mb-8'>
          Image Captioning Evaluation
        </p>
        <p className='text-[40px] font-thin text-white mb-[114px] sm:text-sm sm:font-light sm:mb-[52px]'>
          CVPR 2024 NICE Workshop
        </p>
        <div className='h-[1px] w-full bg-gradient-to-r from-[#b15bff] to-[#56afffcc]'/>
      </div>
    </section>
  )
}
