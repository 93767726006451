import React from 'react';
import sample1 from '../../assets/imgs/img_data01@2x.png';
import sample2 from '../../assets/imgs/img_data02@2x.png';
import license from '../../assets/imgs/img_license@2x.jpg';
import bgSmall from '../../assets/imgs/img_bg_dataset03.png';

export default function Dataset() {
  return (
    <section className='flex flex-col justify-center w-full h-screen mx-auto bg-center bg-no-repeat bg-cover lg:bg-dataset xl:bg-dataset sm:w-full sm:h-fit sm:bg-black sm:justify-start'>
      <div className='w-[1240px] self-center mb-9 sm:w-full sm:flex sm:flex-col'>
        <div className='flex items-center sm:flex-col sm:mb-4'>
          <span className='text-[110px] font-semibold text-[#c90bf9] absolute left-10 h-[130px] leading-[130px] sm:text-[40px] sm:leading-[40px] sm:relative sm:h-[40px] sm:left-0'>03</span>
          <h1 className='text-[84px] sm:text-[26px] font-bold bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text'>Dataset</h1>
        </div>
        <div className='flex gap-5 sm:w-full sm:flex-col sm:px-4'>
          <div className='w-[610px] h-[710px] bg-[#191919] border border-[#727272] rounded-lg flex flex-col sm:w-full sm:h-fit'>
            <div className='w-full h-[535px] border-b border-[#727272] px-16 pt-[52px] pb-10 sm:px-6 sm:pt-6 sm:pb-5 sm:h-fit'>
              <h1 className='text-center font-medium text-white text-[26px] h-[26px] leading-[26px] mb-5 sm:text-[18px] sm:h-[18px] sm:leading-[18px]'>Nice dataset</h1>
              <p className='text-white text-justify leading-[28px] mb-5 sm:text-[15px] sm:leading-6'>
                In 2024 challenge, the dataset is curated with images that were used in the 2023 challenge, but with new annotation by humans. Roughly 21k images are annotated with 5 captions generated by different annotators, so that the set of captions include both diversity and accuracy. With this dataset, we expect the researchers in this community to take a longitudinal evaluation across a variety of metrics to comparatively assess performance of different zero-shot image captioning models.
              </p>
              <p className='text-white text-justify leading-[28px] sm:text-[15px] sm:leading-6'>
                For training the image captioning model, we recommend to choose one or more datasets among the following list, considering scale depending on your available computational cost. Participants are allowed to use other private datasets as long as the detailed description of the data is shared upon winning the prize.
              </p>
            </div>
            <div className='flex w-full pt-10 pb-12 px-9 gap-7 sm:flex-col sm:items-start sm:pt-5 sm:px-6 sm:gap-0 sm:h-fit'>
              <h3 className='text-white font-medium pt-[6px] sm:text-[16px] sm:mb-3'>Link</h3>
              <div className='flex flex-wrap gap-2 sm:flex-col sm:gap-5'>
                <button
                  className='bg-transparent h-9 text-[#ababab] text-sm py-2 px-4 border border-[#767676] rounded-[100px]'
                  onClick={() => {
                    window.open('https://cocodataset.org');
                  }}
                >
                  MS COCO
                </button>
                <button
                  className='bg-transparent h-9 text-[#ababab] text-sm py-2 px-4 border border-[#767676] rounded-[100px]'
                  onClick={() => {
                    window.open('https://ai.google.com/research/ConceptualCaptions');
                  }}
                >
                  CC3M
                </button>
                <button
                  className='bg-transparent h-9 text-[#ababab] text-sm py-2 px-4 border border-[#767676] rounded-[100px]'
                  onClick={() => {
                    window.open('https://github.com/google-research-datasets/conceptual-12m');
                  }}
                >
                  CC12M
                </button>
                <button
                  className='bg-transparent h-9 text-[#ababab] text-sm py-2 px-4 border border-[#767676] rounded-[100px]'
                  onClick={() => {
                    window.open('https://laion.ai');
                  }}
                >
                  LAION-400M / LAION-5B
                </button>
                <button 
                  className='bg-transparent h-9 text-[#ababab] text-sm py-2 px-4 border border-[#767676] rounded-[100px]'
                  onClick={() => {
                    window.open('https://projects.dfki.uni-kl.de/yfcc100m');
                  }}
                >
                  YFCC-15M / YFCC-100M
                </button>
              </div>
            </div>
          </div>
          <div className='w-[610px] h-[710px] bg-[#191919] border border-[#727272] rounded-lg flex flex-col items-center pt-[52px] sm:w-full sm:h-fit sm:pt-7'>
            <h1 className='text-center font-medium text-white text-[26px] h-[26px] leading-[26px] mb-7 sm:text-[18px] sm:h-[18px] sm:leading-[18px] sm:mb-5'>Dataset download</h1>
            <div className='flex justify-center gap-6 flex-wrap px-[60px] sm:px-5 sm:w-full'>
              <div className='flex flex-col gap-2 w-[258px] sm:w-full sm:items-center'>
                <img src={sample1} alt="sample" width={258}/>
                <p className='text-[11px] text-[#ababab] leading-4 tracking-tight'>
                  a river flowing through a small town next to houses.<br/>
                  a small town with a stream running through it.<br/>
                  a small river runs between a row of houses.<br/>
                  a view of a town with a stream in front of it.<br/>
                  a stream running through a town under a cloudy sky.
                </p>
              </div>
              <div className='flex flex-col gap-2 w-[200px] sm:w-full sm:items-center'>
                <img src={sample2} alt="sample" width={184}/>
                <p className='text-[11px] text-[#ababab] leading-4 tracking-tight'>
                  two men in hard hats stand together in a roof structure.<br/>
                  two men in white hard hats and suits are standing in a wooden structure.<br/>
                  two men in white hats looking at a paper on top of a wooden structure.<br/>
                  two men standing underneath a wooden structure while looking at a plan.<br/>
                  two men in white hats look over a building project.
                </p>
              </div>
            </div>
            <div className='flex justify-center gap-4 mt-9 mb-[18px]'>
              <button 
                className='w-[260px] bg-transparent text-white font-medium border border-[#c90bf9] rounded-[70px] py-4'
                onClick={() => {
                  window.open('https://storage.googleapis.com/cvpr-2023-challenge/images_20k.zip');
                }}
              >
                Download Test Dataset
              </button>
            </div>
            <img
              className='cursor-pointer'
              src={license} 
              alt="license" 
              width={60}
              onClick={() => {
                window.open('https://creativecommons.org/licenses/by-nc-nd/4.0/');
              }}
            />
            <p className='w-full text-[11px] tracking-tighter mt-3 text-[#ababab] text-center'>This work is licensed under a <span className='text-[#80c5f4]'>Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License.</span></p>
          </div>
        </div>
        <img className='lg:hidden xl:hidden sm:w-full' src={bgSmall} alt="bg" />
      </div>
    </section>
  )
}
