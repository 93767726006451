import React from 'react'
import InvitedMembersCard from './InvitedMembersCard'

export default function InvitedMembers() {
  return (
    <section className='flex items-start justify-center lg:w-full lg:min-h-[1480px] lg:h-screen xl:w-full xl:min-h-[1480px] xl:h-screen bg-white gap-[140px] pt-[68px] sm:w-full sm:h-fit sm:flex-col sm:items-center sm:pt-8 sm:gap-0 sm:pb-[48px]'>
      <p className='flex flex-col sm:gap-1 sm:flex-row sm:text-[26px] sm:h-[26px] sm:leading-[26px] sm:mb-5 text-[60px] font-bold h-[60px] leading-[60px] bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text'>
        Invited 
        <span className='text-[60px] sm:text-[26px] sm:h-[26px] sm:leading-[26px] font-bold h-[60px] leading-[60px] bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text'>
          Talks
        </span>
      </p>
      <div className='flex flex-col gap-[84px] sm:gap-10'>
        <div className='flex gap-12 sm:flex-col sm:gap-10'>
          <InvitedMembersCard name={'Honglak'}/>
          <InvitedMembersCard name={'Cordelia'}/>
          <InvitedMembersCard name={'Elisa'}/>
        </div>
        <div className='flex gap-12 sm:flex-col sm:gap-10'>
          <InvitedMembersCard name={'Larry'}/>
          <InvitedMembersCard name={'Noa'}/>
          <InvitedMembersCard name={'Lambert'}/>
        </div>
      </div>
    </section>
  )
}
