import React, { useState } from 'react';
import showIcon from '../../assets/imgs/btn_showinfo_n@2x.png'


type InvitedMemberProps = {
  name: string;
}

type MemberData = {
  name: string;
  family: string;
  company: string;
  description: string;
}

const data: MemberData[] = [
  {
    name: 'Honglak',
    family: 'Lee',
    company: `LG AI Research / University of Michigan`,
    description: `Honglak Lee is an associate professor at University of Michigan, Ann Arbor, and also Senior VP and Chief Scientist at LG AI Research. Previously, he worked as a Research Scientist at Google Research, Brain Team. His research interests lie in deep learning and representation learning, graphical models, optimization, and large-scale learning. Specific application areas include computer vision, audio recognition, robotics, text modeling, and healthcare. Based on his broad research experiences, we expect him to provide a deep insight on language-vision models to the community.`
  },
  {
    name: 'Cordelia',
    family: 'Schmid',
    company: `INRIA / Google Research France`,
    description: `Cordelia Schmid is a research director at INRIA and research scientist at Google Research France. She holds a M.S. degree in Computer Science from the University of Karlsruhe and a Doctorate, also in Computer Science, from the Institut National Polytechnique de Grenoble (INPG). Her major research interest lies in visual perception, but she also has experiences in natural language processing and speech processing. We expect her to give the audience a deep insight on visual perception as well as its combination with language models.`
  },
  {
    name: 'Elisa',
    family: 'Kreiss',
    company: `UCLA`,
    description: `Elisa Kreiss is an Assistant Professor of Communication at UCLA and the lab director of the Coalas (Computation and Language for Society) Lab. She investigates how we produce and understand language situated in the visual world. Her work combines tools from natural language processing, psycholinguistics, and human-computer interaction to advance our understanding of how communicative context shapes language use. Her research has direct applications to image accessibility – the challenge of (automatically) generating image descriptions for blind and low vision users.`
  },
  {
    name: 'Noa',
    family: 'Garcia',
    company: `Osaka University`,
    description: `Noa Garcia is an associate professor at Osaka University. She obtained a Ph.D. from Aston University in 2019. Her research interest includes fairness in language-vision models for visual question answering and image captioning, and understanding of artworks. This proposal aims to extend the challenges of current image captioning models, and we hope that various kinds of biases would not appear in the dataset or evaluation.`
  },
  {
    name: 'Larry',
    family:'Davis',
    company: `Amazon`,
    description: `Larry S. Davis is an American computer scientist and professor emeritus at the University of Maryland, College Park. He currently works as a Senior Principal Scientist at Amazon. Davis is best known for his research in the field of computer vision.`
  },
  {
    name: 'Hamid',
    family: 'Palangi',
    company: `Microsoft Research / University of Washington`,
    description: `Hamid Palangi is a Principal Researcher at Microsoft Research, Affiliate Associate Professor at the University of Washington, and Area Editor for the IEEE Signal Processing Magazine Newsletter. His current research interests are in the areas of Artificial Intelligence, Natural Language Processing and Machine Learning.`
  },
  {
    name: 'Lambert',
    family: 'Mathias',
    company: `Meta`,
    description: `Lambert Mathias is currently a Research Scientist at Meta Reality Labs Research, where he leads research in contextual AI, focusing on the development of advanced large language model (LLM) agents designed for reasoning across long range egocentric data streams. His prior role as a Principal Applied Scientist at Alexa AI involved establishing the foundational architecture for Alexa's multi-domain natural language understanding systems, with an emphasis on neural methodologies for semantic parsing. His research explores the integration of egocentric sensor streams with LLMs to enhance multimodal reasoning capabilities. Dr. Mathias obtained his PhD from the Center for Language and Speech Processing at Johns Hopkins University; where his research focused on statistical speech translation and automatic speech recognition.`
  }
]

export default function InvitedMembersCard({name}: InvitedMemberProps) {
  const [descOn, setDescOn] = useState<boolean>(false);
  return (
    <div className='flex flex-col'>
      <div className={`${descOn? ' bg-[]' : 'bg-black'} w-[340px] h-[480px] bg-black rounded-lg mb-5`}>
        <div className={`${descOn && 'bg-[#00000066]'} w-full h-full bg-${name} px-8 pt-8 overflow-auto`}>
          <div 
            className='flex items-center gap-2 cursor-pointer mb-3'
            onClick={() => {setDescOn((prev) => (!prev))}}>
            <img src={showIcon} alt="show" width={24}/>
            <span className='text-white'>show</span>
          </div>
          {descOn && 
            <p className='text-white leading-6 font-light'>
              {data.find(item => item.name === name)?.description}
            </p>
          }
        </div>
      </div>
      <div className='flex flex-col'>
        <h2 className='font-bold text-[26px] mb-2 h-[26px] leading-[26px]'>{`${data.find(item =>  item.name === name)?.name} 
              ${data.find(item => item.name === name)?.family}`}
        </h2>
        <p className='text-[18px]'>{data.find(item => item.name === name)?.company}</p>
      </div>
    </div>
  )
}
