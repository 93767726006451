import React from 'react'
import PeopleCard from './PeopleCard'

export default function People() {
  return (
    <section className='w-full min-h-[1252px] h-screen bg-black pt-[88px] sm:w-full sm:h-fit sm:flex sm:flex-col sm:items-center sm:py-10'>
      <div className='w-full flex justify-center pb-[48px] sm:flex-col sm:items-center sm:pb-4'>
        <span className='absolute left-10 text-[110px] font-semibold text-[#c90bf9] h-[130px] leading-[130px] sm:relative sm:left-0 sm:text-[40px] sm:h-[40px] sm:leading-10'>05</span>
        <span className='text-[84px] sm:text-[26px] font-bold bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text'>People</span>
      </div>
      <div className='w-full h-[1px] bg-[#202020] mb-[60px] sm:mb-4'/>
      <div className='flex flex-col items-center gap-12'>
        <div className='flex gap-10 sm:flex-col sm:gap-5'>
          <h3 className='text-white text-xl font-medium sm:text-center'>Organizing <br className='sm:hidden'/>Commitee</h3>
          <PeopleCard name={'SeungHwan'}/>
          <PeopleCard name={'KyoungMu'}/>
          <PeopleCard name={'BoHyung'}/>
          <PeopleCard name={'Larry'}/>
        </div>
        <div className='flex gap-5 sm:flex-col'>
          <h3 className='text-white text-xl font-medium mr-[18px] sm:text-center'>Technical <br className='sm:hidden'/>Commitee</h3>
          <PeopleCard name={'TaeHoon'}/>
          <PeopleCard name={'PyungHwan'}/>
          <PeopleCard name={'Gwangmo'}/>
          <PeopleCard name={'Emily'}/>
          <PeopleCard name={'Youngjoon'}/>
        </div>
      </div>
    </section>
  )
}
