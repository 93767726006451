import React, { useRef, useState } from 'react';
import Challenge from './components/Challenge/Challenge';
import Dataset from './components/Dataset/Dataset';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home/Home';
import Introduction from './components/Introduction/Introduction';
import InvitedMembers from './components/InvitedMembers/InvitedMembers';
import People from './components/People/People';
import Workshop from './components/Workshop/Workshop';


function App() {
  const menuRef = useRef<HTMLLIElement>(null);
  const [year, setYear] = useState<string>('2024');

  function handleMenuClick (event: React.MouseEvent<HTMLElement>) {
    console.log(event.target);
  };

  const handleYearSelect = (year: number) => {
    console.log(year);
  }

  return (
    <>
      <div className='flex flex-col sm:w-full lg:w-[1920px] xl:w-screen'>
        <Header 
          onMenuClick={handleMenuClick}
          onYearSelect={handleYearSelect} 
          year={year}
        />
        <Home />
        <Introduction />
        <Challenge />
        <Dataset />
        <Workshop />
        <InvitedMembers />
        <People />
        <Footer />
      </div>
    </>
  );
}

export default App;
