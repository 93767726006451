import React from 'react';
import challengeDesc from '../../assets/imgs/ico_challenge_description@2x.png';
import challengeDate from '../../assets/imgs/ico_challenge_date@2x.png';


export default function Challenge() {
  return (
    <section className='w-full h-screen min-h-[1300px] bg-challenge bg-no-repeat bg-center bg-cover flex flex-col items-center justify-center sm:w-full sm:px-4 sm:py-8 sm:h-fit sm:justify-start'>
      <span className='text-[110px] font-semibold text-[#c90bf9] h-[130px] leading-[130px] sm:text-[40px] sm:leading-10 sm:h-10'>02</span>
      <h1 className='text-[84px] sm:text-[26px] sm:mb-5 font-bold bg-gradient-to-l from-[#c90bf9] via-[#52b9ff] to-indigo-400 inline-block text-transparent bg-clip-text'>Challenge</h1>
      <div className='w-[1240px] h-[768px] bg-[#191919] rounded-lg border border-[#666] flex py-[40px] sm:w-full sm:flex-col sm:h-fit sm:py-7'>
        <div className='flex flex-col px-[70px] items-center w-[50%] sm:w-full sm:px-6 sm:h-fit'>
          <img src={challengeDesc} alt="desc" width={24}/>
          <p className='text-[26px] sm:text-[18px] text-white font-medium text-center leading-[34px] sm:leading-[28px] mt-5 mb-10 sm:mt-2 sm:mb-4'>
            2024 Challenge on <br/>
            zero-shot image captioning evaluation
          </p>
          <div className='mb-2'>
            <div className='flex items-center gap-3 mb-2'>
              <span className='w-[54px] h-[26px] bg-white rounded-[60px] font-semibold text-[11px] text-center leading-[26px]'>Topic 1</span>
              <span className='font-light text-white sm:text-[15px]'>Zero-shot image captioning evaluation</span>
            </div>
            <p className='text-white font-light leading-[28px] text-justify pl-3 sm:text-[15px] sm:leading-6 sm:mb-2'>
              • AI models will be evaluated with their ability to generate captions that describe the image accurately and fluently, by comparing them to human-generated ones.​
            </p>
          </div>
          <div className='mb-2'>
            <div className='flex items-center gap-3 mb-2'>
              <span className='w-[54px] h-[26px] bg-white rounded-[60px] font-semibold text-[11px] text-center leading-[26px]'>Topic 2</span>
              <span className='font-light text-white sm:text-[15px]'>Caption re-ranking evaluation​</span>
            </div>
            <p className='text-white font-light leading-[28px] text-justify pl-3 sm:text-[15px] sm:leading-6 sm:mb-2'>
            • Algorithms will be evaluated with their ability to rank captions so that those with higher ranks describe the image accurately and thoroughly.​
            </p>
          </div>
          <p className='font-light leading-[28px] text-white mb-3 text-justify sm:text-[15px] sm:leading-6 sm:mb-2'>
            • We will evaluate the caption accuracy using the following set of commonly employed caption evaluation metrics: CIDEr, SPICE, METEOR, ROGUE and BLEU.​
          </p>
          <p className='font-light leading-[28px] text-[#baa0fd] mb-3 text-justify sm:text-[15px] sm:leading-6 sm:mb-2'>
            • The winner of each topic will be given prize money of $5,000 and the runner-up will be given $2,500.
          </p>
          <p className='font-light leading-[28px] text-white text-justify sm:text-[15px] sm:leading-6 sm:mb-2'>
            • Every prize-winning team will be requested to submit a tech report and give a short talk on their approach at the workshop. For detailed license statements, please look through the website before submission.​
          </p>
        </div>
        <div className='flex flex-col px-[70px] items-center w-[50%] sm:w-full sm:h-fit sm:px-6 sm:mt-6'>
          <img src={challengeDate} alt="date" width={24}/>
          <p className='text-[26px] text-white font-medium text-center leading-[34px] mt-5 mb-7 sm:text-[18px] sm:mt-2 sm:mb-0'>
            Dates
          </p>
          <span className='mb-10 text-white sm:font-light sm:mb-2'>Challenge Schedule</span>
          <div className='flex flex-col gap-7 sm:gap-3'>
            <div className='w-full h-[1px] bg-[#454545]'/>
            <p className='text-[#f5ceff] font-light sm:text-sm'>
              • Evaluation server open (Test data release)&nbsp;&nbsp;
              <b className='font-bold'>01 / 15 / 2024​</b>
            </p>
            <div className='w-full h-[1px] bg-[#454545]'/>
            <p className='text-[#f5ceff] font-light sm:text-sm'>
              • Submission deadline&nbsp;&nbsp;
              <b className='font-bold'>03 / 21 / 2024​</b>
            </p>
            <div className='w-full h-[1px] bg-[#454545]'/>
            <p className='text-[#f5ceff] font-light sm:text-sm'>
              • Notification of winners&nbsp;&nbsp;
              <b className='font-bold'>04 / 04 / 2024​</b>
            </p>
            <div className='w-full h-[1px] bg-[#454545]'/>
            <p className='text-[#f5ceff] font-light sm:text-sm'>
              • Tech report deadline (for winners)&nbsp;&nbsp;
              <b className='font-bold'>04 / 10 / 2024​</b>
            </p>
            <div className='w-full h-[1px] bg-[#454545]'/>
          </div>
        </div>
      </div>
      <h2 className='mt-10 mb-5 text-2xl font-medium text-white sm:text-lg sm:mt-6 sm:mb-4'>What to see more?</h2>
      <button
        className='w-[200px] h-14 bg-transparent border border-[#c90bf9] text-white rounded-[70px] cursor-pointer sm:text-lg'
        onClick={() => {
          window.open('https://codalab.lisn.upsaclay.fr/competitions/16930');
        }}
      >
        Submission
      </button>
    </section>
  )
}
